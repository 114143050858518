import React from "react"
import Helmet from "react-helmet"
import Layout from "../../../components/layout"
import { Container, Row, Col } from "react-bootstrap"
import JumboPhoto from "../../../components/jumbophoto"

export default () => <Layout>
	<Helmet title="Reaffirmation Agreements | Gina Mcdonald &amp; Associates, L.L.C." defer={false}>
		<meta name="description" content="Birmingham Bankruptcy attorneys assisting clients to negotiate reaffirmation agreements in Gardendale, Helena, Pelham or anywhere else in Alabama"/>
	</Helmet>

	<JumboPhoto/>

	<Container>
		<Row>
			<Col>
				<p>Reaffirmation agreements are contracts between a debtor in bankruptcy and a creditor that reaffirm the debt that would otherwise be discharged in bankruptcy, thereby allowing the creditor to continue to pursue the claim after bankruptcy. In essence, the debtor waives discharge as to a certain debt, promising to pay the creditor even though the debtor would not be obligated to absent the reaffirmation. Reaffirmation agreements are strictly voluntary – a creditor cannot force a debtor to sign a reaffirmation, nor can a debtor or debtor’s attorney force a creditor into a reaffirmation agreement. Debtors should carefully consider whether he or she will be able to make the promised payments, because after discharge, the debtor may not receive another discharge for eight years.</p>

				<p>Sometimes a reaffirmation agreement is a good idea. For instance, suppose you borrowed money in order to purchase a car some years ago, and have managed to keep current on the payments despite falling behind with other creditors. You likely have a good bit of equity in the vehicle. However, the agreement you signed when you borrowed the purchase price might specify that a bankruptcy filing is an event of default. This would mean that after the discharge, even though you never fell behind on your payments, the lender could repossess the vehicle. The lender may be worried about your ability to repay and wants the extra assurance by means of a reaffirmation agreement.</p>

				<p>Sometimes, however, reaffirmation is a terrible idea. For instance, most lawyers never recommend that a debtor sign a reaffirmation agreement with regards to a home mortgage loan. This is because if you fall behind again on your mortgage payments, the bank can not only foreclose and sell your home, but come after you individually for any deficiency resulting from the sale.</p>

				<p>Our attorneys will explain the reaffirmation process to you and explain the pros and cons of executing the reaffirmation agreements. Please call 205-982-3325 to set up your free consult with a qualified bankruptcy attorney.</p>
			</Col>
		</Row>
	</Container>
</Layout>